import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'global'
})
export class GlobalPipe implements PipeTransform {
  transform(valName: string): string {
    return {
      appearance: 'standard'
    }[valName];
  }
}
