import { TableAction } from './table-action';

export class WithActions {
  showFloatingButtons: Map<string,boolean>;
  enabledActions: Map<string,TableAction[]>;

  public static reset(element: WithActions){
    element.enabledActions = null;
  }

  public static toggleFloatingButtons(_tableId: string, _element: WithActions){
    //do nothing since we are no more using floating buttons for actions, but vertical menu
    return;
  }

}
