import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CoreAppElementsActivation, CoreAppService } from '../../../services/core-app.service';

@Component({
  selector: 'app-charity-esito',
  templateUrl: './charity-esito.component.html',
  styleUrls: ['./charity-esito.component.scss']
})
export class CharityEsitoComponent implements OnInit {

  private backUrl: string;
  esito: boolean;

  constructor(
    private router:Router,
    private route:ActivatedRoute,
    private coreAppService: CoreAppService,
  ) { }

  ngOnInit(): void {
    const coreAppElements = new CoreAppElementsActivation();
    coreAppElements.sidenav = false;
    coreAppElements.header = false;
    this.coreAppService.setState(coreAppElements);

    this.esito = this.route.snapshot.queryParamMap.get('esito') === 'OK';
    this.backUrl = this.route.snapshot.queryParamMap.get('backUrl');
  }

  complete(): void {
    if(this.backUrl)
      window.location.href = this.backUrl;
    else {
      this.coreAppService.resetState();
      this.router.navigate(['home']);
    }
  }

}
