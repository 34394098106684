import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

export class MailValidationRequest {
  email: string;
  encryptedSalt: string;
  pin: string;

  error: string;
}

export class MailValidationResponse {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'expiration','unix-ms'),
  ];

  email: string;
  validatedToken: string;
  validationStatus: string;
  expiration: DateTime;
}
