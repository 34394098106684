import { Component, OnInit } from '@angular/core';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  get titleLabel(){ return "FAQ" }
  get titleIcon(){ return faQuestion }

  constructor() { }

  ngOnInit(): void {
  }

}
