import { TableAction } from 'projects/mypay4-fe-common/src/public-api';
import { BehaviorSubject, Subscription } from 'rxjs';

import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { DynamicPipe } from '../../pipes/dynamic-pipe';
import { TableColumn } from '../../table/table-column';

export type KeyValue = { [key: string]: string };
export type UpdateDetailFun = (details:BehaviorSubject<KeyValue[]>)=>void;

@Component({
  selector: 'lib-my-pay-table-detail',
  templateUrl: './my-pay-table-detail.component.html',
  styleUrls: ['./my-pay-table-detail.component.scss']
})
export class MyPayTableDetailComponent implements OnDestroy {

  public static DIALOG_ID = 'mypay4-table-detail-dialog';

  public static SECTION_ID = '__MYPAY_SECTION__';
  public static NO_DETAIL = '__NO_MYPAY_DETAIL__';

  public detailsGroups: any;
  public detailsGroupsLabel: any;
  public element: any;
  public tableId: string;
  public parentRef: any;
  public actionColumn: TableColumn;
  public detailFilterInclude: string[];
  public detailFilterExclude: string[];
  private detailSubjectSubscription: Subscription;

  //Ente primario details
  public detailsEntePrimarioGroups: any;
  public detailsEntePrimarioLabel: any;

  //multibeneficiario
  public elementMultibeneficiario: any
  public detailsMBGroups: any;
  public detailsMBGroupsLabel: any;


  iconTimes = faTimes;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dynamicPipe: DynamicPipe,
    private matDialog: MatDialog,
  ) {
    this.element = data.element;
    this.tableId = data.tableId;
    this.parentRef = data.parentRef;
    this.detailFilterInclude = data.detailFilterInclude;
    this.detailFilterExclude = data.detailFilterExclude;
    this.elementMultibeneficiario = data.dovutoMultibeneficiario;

    let detailSubject:BehaviorSubject<KeyValue[]>;
    if(this.element.details instanceof BehaviorSubject){
      detailSubject = this.element.details;
    } else {
      detailSubject = new BehaviorSubject(this.element.details);
    }

    this.detailSubjectSubscription = detailSubject.subscribe(newDetails => {
      const detailsGroups = [];
      const detailsGroupsLabel = [];
      let details = [];
      data.tableColumnns.forEach((aColumn:TableColumn) => {
        if(!aColumn.actions && !aColumn.isImg && aColumn.id!=="tableExpandColumn")
          details.push({key:aColumn.label, value: this.dynamicPipe.transform(this.element[aColumn.id], aColumn.pipe, aColumn.pipeArgs)})
        if(aColumn.actions)
          this.actionColumn = aColumn;
      });
      if(details){
         //remove elemente 'Multi beneficiario' from detailsGroups
        let indexMB = details.findIndex(el => el.key === 'Multi beneficiario');
        details.splice(indexMB,1);

        detailsGroupsLabel.push(null);
        detailsGroups.push(details);
      }


      newDetails?.forEach(element => {
        if(element.key === MyPayTableDetailComponent.SECTION_ID){
          detailsGroupsLabel.push(element.value);
          details = [];
          detailsGroups.push(details);
        } else {
          if(!details){
            detailsGroupsLabel.push(null);
            detailsGroups.push(details);
          }
          const alreadyPresentDetail = details.find(elem => elem.key === element.key);
          if(alreadyPresentDetail)
            alreadyPresentDetail.value = element.value;
          else
            details.push(element);
        }
      });


      this.detailsGroups = detailsGroups;
      this.detailsGroupsLabel = detailsGroupsLabel;
      
    });

    //detail Ente primario 
    if(this.element.detailEntePrimario != null) {
      const detailsEnteGropups = [];
      const detailEnteGropupsLabel = [];
      let detailsEnte = [];

      this.element.detailEntePrimario.forEach(element => {
        detailEnteGropupsLabel.push(element.key)
        detailsEnte.push(element);
      });
      detailsEnteGropups.push(detailsEnte);
      this.detailsEntePrimarioGroups = detailsEnteGropups;
      this.detailsEntePrimarioLabel = detailEnteGropupsLabel;
    }

    //detail multibeneficiario
    if(this.element.detailMultiBeneficiario != null) {
      const detailsMBGroups = [];
      const detailsMBGroupsLabel = [];
      let detailsMB = [];

      this.element.detailMultiBeneficiario.forEach(element => {
        detailsMBGroupsLabel.push(element.key)
        detailsMB.push(element);
      });
      detailsMBGroups.push(detailsMB);
      this.detailsMBGroups = detailsMBGroups;
      this.detailsMBGroupsLabel = detailsMBGroupsLabel;
    }
    
  }

  clickAction(action: TableAction, eventRef: any):void {
    MyPayTableDetailComponent.close(this.matDialog);
    action.click(this.tableId, this.element, this.parentRef, eventRef);
  }

  ngOnDestroy(): void {
    this.detailSubjectSubscription?.unsubscribe();
  }

  static close(matDialog: MatDialog){
    matDialog.getDialogById(MyPayTableDetailComponent.DIALOG_ID)?.close();
  }

  public static markNoDetail(element: any){
    element.details = null;
    element[MyPayTableDetailComponent.NO_DETAIL] = true;
  }

  private formatStringNumber(str: string): string {
    str = str.replace("€", "");
    str = str.replace(".", "");
    str = str.split(",").join(".").trim()
    return str;
  }

}
