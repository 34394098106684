import { Component, OnInit } from '@angular/core';
import { faGavel } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  get titleLabel(){ return "Note legali" }
  get titleIcon(){ return faGavel }

  constructor() { }

  ngOnInit(): void {
  }

}
