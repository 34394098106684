import { Directive, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { AbstractTextOperationDirective } from './abstact-text-operation.directive';

@Directive({
  selector: "input[removeSpace], textarea[removeSpace]",
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: RemoveSpaceDirective, multi: true }]
})
export class RemoveSpaceDirective extends AbstractTextOperationDirective {

  // Get a value of the removeSpace attribute if it was set.
  @Input() removeSpace: string;

  protected processValue(value: string): string{
    return value?.replace(/\s*/g, "");
  }

  protected eventValue(): string {
    return this.removeSpace;
  }

}
