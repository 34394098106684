
import * as _ from 'lodash';
import { Subject } from 'rxjs';

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';

import { Mappers } from '../mapper/mappers';
import { ApiInvokerService } from './api-invoker.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  appPath: string;
  localStorage: Storage;

  changes$ = new Subject();

  constructor(
    private location: Location,
    private apiInvokerService: ApiInvokerService,
  ) {
    this.localStorage   = window.localStorage;
    this.appPath = this.location.prepareExternalUrl('');
    console.log('app path (base href): '+this.appPath);
  }

  get(key: string, mappers?:Mappers): any {
    if (this.isLocalStorageSupported) {
      const resp = JSON.parse(this.localStorage.getItem(this.appPath+'-'+key));
      return this.apiInvokerService.applyS2CMapper(resp, mappers);
    }

    return null;
  }

  set(key: string, value: any, mappers?:Mappers): boolean {
    if (this.isLocalStorageSupported) {
      const req = JSON.stringify(this.apiInvokerService.applyC2SMapper(_.cloneDeep(value), mappers));
      this.localStorage.setItem(this.appPath+'-'+key, req);
      this.changes$.next({
        type: 'set',
        key,
        value
      });
      return true;
    }

    return false;
  }

  remove(key: string): boolean {
    if (this.isLocalStorageSupported) {
      this.localStorage.removeItem(this.appPath+'-'+key);
      this.changes$.next({
        type: 'remove',
        key
      });
      return true;
    }

    return false;
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }
}
