import * as _ from 'lodash';

export class Person {
  public static CF_ANONIMO = 'ANONIMO';
  private __isPersonObject = true;

  codiceIdentificativoUnivoco: string
  tipoIdentificativoUnivoco?: 'F'|'G';
  anagrafica?: string
  email?: string
  indirizzo?: string
  civico?: string
  cap?: string
  nazione?: string
  provincia?: string
  localita?: string
  nazioneId?: number
  provinciaId?: number
  localitaId?: number

  public static ANONIMO():Person{
    const anonimo = new Person();
    anonimo.tipoIdentificativoUnivoco = 'F';
    anonimo.codiceIdentificativoUnivoco = Person.CF_ANONIMO;
    return anonimo;
  }

  public static normalizeAnonimoDetails(person: Person): Person {
    if(!person?.__isPersonObject)
      person = _.merge(new Person(), person);
    person.tipoIdentificativoUnivoco = 'F';
    person.codiceIdentificativoUnivoco = Person.CF_ANONIMO;
    return person;
  }

  public static isPersonAnonimo(person: Person):boolean {
    return !person || !person.codiceIdentificativoUnivoco || person.codiceIdentificativoUnivoco===Person.CF_ANONIMO;
  }

  public static idIntestatario(person: Person):string {
    if(!person)
      return;
    //return a simple hash (inspired by java hashcode) of "CF + name + email"
    //console.log(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email, Array.from(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0));
    return Array.from(person.codiceIdentificativoUnivoco + "|" + person.anagrafica + "|" + person.email)
        .map(s => s.toLowerCase())
        .reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0).toString();
  }
}
