import { BREAKPOINT } from '@angular/flex-layout';

export const BS4_BREAKPOINTS = [
    { alias: 'gt-bsmd', mediaQuery: 'screen and (min-width: 768px)', overlapping: false, priority: 1001 },
    { alias: 'lt-bsmd', mediaQuery: 'screen and (max-width: 767px)', overlapping: false, priority: 1001 },
    { alias: 'bsmd', mediaQuery: 'screen and (min-width: 768px) and (max-width: 959px)', overlapping: false, priority: 1001 },
];

export const CustomBreakPointsProvider = {
    provide: BREAKPOINT,
    useValue: [...BS4_BREAKPOINTS],
    multi: true,
};
