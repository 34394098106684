<mat-toolbar role="navigation" color="primary" aria-label="Toolbar principale">
  <mat-toolbar-row role="heading" aria-level="1">
    <a fxShow="false" fxShow.gt-xs href="https://mypa.regione.veneto.it/mypa/a"><img class="logo" src="assets/images/logo-mypa-neg.svg" alt="MyPa Home" height="50"></a>
    <a fxShow="false" fxShow.gt-sm href="https://www.regione.veneto.it/"><img class="logo" src="assets/images/logo-regione-veneto--bw.png" alt="Logo regione Veneto"></a>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container" role="navigation">
  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1">Sostegno Emergenza Coronavirus</h1>

    <h3 *ngIf="esito" class="mat-h3 my-2">Pagamento completato con successo</h3>

    <h3 *ngIf="!esito" class="mat-h3 my-2">Si è verificato un errore durante il pagamento</h3>

    <div class="w100">

      <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
        <button type="button" mat-flat-button color="accent" (click)="complete()"> Continua </button>
      </div>

    </div>
  
  </div>
</div>