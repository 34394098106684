import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(input:Array<any>, sep = ', ', map?:{}): string {
    if(map)
      input = input?.map(elem => map?.[elem] || elem);
    return input?.join(sep);
  }
}
