

import { ItemCarrello } from './item-carrello';
import { Person } from './person';

export class Carrello {
  totalAmount: number;
  idSession: string;
  items: ItemCarrello[];
  //detail of intestatario
  versante: Person;
  tipoCarrello: string;
  dovutiEntiSecondari: any;

  //used to verify email for anonymous
  mailValidationToken: string;

  backUrlInviaEsito: string;
}
