import { ToastrService } from 'ngx-toastr';
import {
  manageError, OverlaySpinnerService, WithTitle
} from 'projects/mypay4-fe-common/src/public-api';

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import {
  faAngleRight, faCashRegister, faCreditCard, faFileInvoice, faHome, faInfoCircle, faTags
} from '@fortawesome/free-solid-svg-icons';

import { Debito } from '../../model/debito';
import { Pagato } from '../../model/pagato';
import { DebitoService } from '../../services/debito.service';
import { PagatoService } from '../../services/pagato.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements AfterViewInit, WithTitle {

  @ViewChild('cardPagati', { read: ElementRef }) cardPagati: ElementRef;
  @ViewChild('cardDebiti', { read: ElementRef }) cardDebiti: ElementRef;

  get titleLabel(){ return "Bacheca pagamenti" }
  get titleIcon(){ return faHome }

  iconAngleRight = faAngleRight;
  iconCashRegister = faCashRegister;
  iconCreditCard = faCreditCard;
  iconFileInvoice = faFileInvoice;
  iconTags = faTags;
  iconInfo = faInfoCircle;

  listPagati: Pagato[];
  listDebiti: Debito[];

  constructor(
    private pagatoService: PagatoService,
    private debitoService: DebitoService,
    private toastrService: ToastrService,
    private overlaySpinnerService: OverlaySpinnerService) {
  }

  ngAfterViewInit() {
    //load "storico transazioni"
    const spinnerPagati = this.overlaySpinnerService.showProgress(this.cardPagati);
    this.pagatoService.searchLastPagati(3)
      .subscribe(data => {
        this.listPagati = data.map(p => {
          p.dataPagamentoDay = p.dataPagamento?.toFormat('dd');
          p.dataPagamentoMonth = p.dataPagamento?.toFormat('MMM');
          p.dataPagamentoYear = p.dataPagamento?.toFormat('yyyy');
          return p;
        });
        this.overlaySpinnerService.detach(spinnerPagati);
      }, manageError('Errore ricercando Storico Transazioni', this.toastrService, () => {this.overlaySpinnerService.detach(spinnerPagati)}) );
    //load "posizioni aperte"
    const spinnerDebiti = this.overlaySpinnerService.showProgress(this.cardDebiti);
    this.debitoService.searchLastDebiti(3)
      .subscribe(data => {
        this.listDebiti = data.map(d => {
          d.dataScadenzaDay = d.dataScadenza?.toFormat('dd');
          d.dataScadenzaMonth = d.dataScadenza?.toFormat('MMM');
          d.dataScadenzaYear = d.dataScadenza?.toFormat('yyyy');
          return d;
        });
        this.overlaySpinnerService.detach(spinnerDebiti);
      }, manageError('Errore ricercando Storico Transazioni', this.toastrService, () => {this.overlaySpinnerService.detach(spinnerDebiti)}) );
  }

}
