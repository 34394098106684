import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import { WithActions } from './with-actions';

export interface OverlayText {
  text: string;
  style?: string;
  transform?: string;
  class?: string;
}
export class TableAction {

  public static readonly ACTION_ID_DETTAGLIO = "__dettaglio__";

  icon: IconDefinition;
  tooltip?: string;
  clickFun: (elementRef: WithActions, thisRef: any, eventRef: any) => any;
  clickEnabledFun: (elementRef: WithActions, thisRef: any) => boolean;
  overlayText?: OverlayText;
  id: string;

  constructor(icon: IconDefinition,
    clickFun: (elementRef: WithActions, thisRef: any, eventRef: any) => any,
    clickEnabledFun: (elementRef: WithActions, thisRef: any) => boolean,
    tooltip?: string,
    overlayText?: OverlayText,
    id?: string
    ) {
    this.icon = icon;
    this.clickFun = clickFun;
    this.clickEnabledFun = clickEnabledFun;
    this.tooltip = tooltip;
    this.overlayText = overlayText;
    this.id = id;
  }

  click(tableId, elementRef: WithActions, thisRef: any, eventRef: any): any {
    const ret = this.clickFun(elementRef, thisRef, eventRef);
    WithActions.toggleFloatingButtons(tableId, elementRef);
    return ret;
  }
}
