import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CoreAppService {

  private DEFAULT_STATE = new CoreAppElementsActivation();

  private activationState = new BehaviorSubject<CoreAppElementsActivation>(this.DEFAULT_STATE);

  constructor() { 
    //This is intentionally empty
  }

  public setState(newState: CoreAppElementsActivation){
    const currentState = this.activationState.getValue();
    if(!CoreAppElementsActivation.isEqual(currentState, newState))
      this.activationState.next(new CoreAppElementsActivation(newState));
  }

  public resetState(){
    this.setState(this.DEFAULT_STATE);
  }

  public getStateObs():Observable<CoreAppElementsActivation> {
    return this.activationState.asObservable();
  }
}

export class CoreAppElementsActivation {
  header: boolean = true;
  sidenav: boolean = true;
  footer: boolean = true;

  constructor(elem?: CoreAppElementsActivation){
    if(elem){
      this.header = elem.header;
      this.sidenav = elem.sidenav;
      this.footer = elem.footer;
    }
  }

  static isEqual(first: CoreAppElementsActivation, second: CoreAppElementsActivation){
    return !first && !second ||
      first?.header === second?.header &&
      first?.sidenav === second?.sidenav &&
      first?.footer === second?.footer;
  }
}
