// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2024-11-14T11:37:32+01:00',
  gitHash:'ebc4b06ad',
  branchName:'gpd',
  tag:'v1.3.82_gpd',
  version:version
};
