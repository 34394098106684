import { Component, OnInit } from '@angular/core';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  get titleLabel(){ return "Privacy" }
  get titleIcon(){ return faUserShield }

  constructor() { }

  ngOnInit(): void {
  }

}
