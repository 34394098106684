import { FieldBean } from 'projects/mypay4-fe-common/src/public-api';

import { Person } from './person';

export class SpontaneoForm {
  fieldBeans: Array<FieldBean>;
  importo: string;
  campoTotaleInclusoInXSD: string;
  reCaptchaPublicKey: string;
  definitionDovuto: Array<DefinitionDovuto>;
  intestatario: Person;
}

export class DefinitionDovuto {

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }

  name: string;
  value: string;
}
