import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ElementRef, Injectable } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { DynamicOverlay } from './dynamic-overlay.service';
import { OverlaySpinnerContainerComponent } from './overlay-spinner-container.component';

@Injectable()
export class OverlaySpinnerService {

  constructor(private dynamicOverlay: DynamicOverlay) {
    //This is intentionally empty
   }

  public showProgress<T>(elRef: ElementRef, component: ComponentType<T> = null) {
    if (elRef) {
      const result: OverlaySpinnerRef = { subscription: null, overlayRef: null };
      result.subscription = timer(500)
        .subscribe(() => {
          this.dynamicOverlay.setContainerElement(elRef.nativeElement);
          const positionStrategy = this.dynamicOverlay.position().global().centerHorizontally().centerVertically();
          result.overlayRef = this.dynamicOverlay.create({
            positionStrategy: positionStrategy,
            hasBackdrop: true
          });
          if(component)
            result.overlayRef.attach(new ComponentPortal(component));
          else
            result.overlayRef.attach(new ComponentPortal(OverlaySpinnerContainerComponent));
        });
      return result;
    } else {
      return null;
    }
  }

  detach(result: OverlaySpinnerRef) {
    if (result) {
      result.subscription.unsubscribe();
      if (result.overlayRef) {
        result.overlayRef.detach();
      }
    }
  }
}

export declare type OverlaySpinnerRef = { subscription: Subscription, overlayRef: OverlayRef };
