import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

export class UserMailValidation {

  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'expireDt','local-date-time'),
  ];

  emailAddress: string;
  status: string;
  remainingTries: number;
  expireDt: DateTime;
  cantRequestNewMailBeforeSeconds: number;
  outcome: string;
}
