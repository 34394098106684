import { HttpErrorResponse } from '@angular/common/http';

export class AlreadyManagedError extends HttpErrorResponse {
  ignore:boolean;

  constructor(httpErrorResponse: HttpErrorResponse, ignore: boolean = true){
    super(httpErrorResponse)
    this.ignore = ignore;
  }
}
