export class MapperDef {
  type: MapperType;
  property: string;
  format: any;

  constructor(type: MapperType, property: string, format: any = null){
    this.type = type;
    this.property = property;
    this.format = format;
  }
}

export enum MapperType {
  DateTime,
  Number,
  Currency,
  Function,
  Rename,
  Boolean,
}
