import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  htmlContent: string;
  invalid: boolean = false;
  confirmLabel: string;
  cancelLabel: string;
  titleLabel: string;
  checkboxLabel: string;

  checkboxEnabled: boolean = false;
  checkboxState: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.htmlContent = this.domSanitizer.sanitize(SecurityContext.HTML, this.data?.message);
    this.invalid = this.data?.invalid ?? false;
    this.confirmLabel = this.data?.confirmLabel ?? 'Conferma';
    this.cancelLabel = this.data?.cancelLabel ?? 'Annulla';
    this.titleLabel = this.data?.titleLabel ?? 'Conferma operazione';
    if(this.data?.checkboxLabel){
      this.checkboxLabel = this.data?.checkboxLabel;
      this.checkboxEnabled = true;
    }
  }

  manageClose(result: boolean){
    this.dialogRef.close(result+(this.checkboxState?'_checked':''));
  }

}
