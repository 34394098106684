export class CodeLabel{
  code: string;
  label: string;
  detailText: string;

  constructor(code:string, label?:string, detailText?:string){
    this.code = code;
    this.label = label;
    this.detailText = detailText;
  }
}
