<mat-toolbar role="navigation" color="primary" aria-label="Toolbar principale">
  <mat-toolbar-row role="heading" aria-level="1">
    <a fxShow="false" fxShow.gt-xs href="https://mypa.regione.veneto.it/mypa/a"><img class="logo" src="assets/images/logo-mypa-neg.svg" alt="MyPa Home" height="50"></a>
    <a fxShow="false" fxShow.gt-sm href="https://www.regione.veneto.it/"><img class="logo" src="assets/images/logo-regione-veneto--bw.png" alt="Logo regione Veneto"></a>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container" role="navigation">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <h1 class="mat-h1">Sostegno Emergenza Coronavirus</h1>

    <div class="w100 mypay-search-form">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-label id="sr-nome" class="sr-only">Nome</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Nome</mat-label>
            <input aria-labelledby="sr-nome" matInput formControlName="nome" required trim="blur">
            <mat-error *ngIf="formErrors['nome']">{{ formErrors.nome }}</mat-error>
          </mat-form-field>

          <mat-label id="sr-cognome" class="sr-only">Cognome</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Cognome</mat-label>
            <input aria-labelledby="sr-cognome" matInput formControlName="cognome" required trim="blur">
            <mat-error *ngIf="formErrors['cognome']">{{ formErrors.cognome }}</mat-error>
          </mat-form-field>

          <mat-label id="sr-codFiscale" class="sr-only">Codice fiscale</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="30%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Codice fiscale</mat-label>
            <input aria-labelledby="sr-codFiscale" matInput formControlName="codFiscale" trim="blur">
            <mat-error *ngIf="formErrors['codFiscale']">{{ formErrors.codFiscale }}</mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-label id="sr-email" class="sr-only">Email</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Email</mat-label>
            <input aria-labelledby="sr-email" matInput formControlName="email" required trim="blur">
            <mat-error *ngIf="formErrors['email']">{{ formErrors.email }}</mat-error>
          </mat-form-field>

          <div class="mat-form-field" fxFlex="100%" fxFlex.gt-sm="60%">
            <mat-checkbox formControlName="anonym">
              Seleziona questa casella per rendere anonima la donazione, in alternativa i tuoi dati potranno essere pubblicati nella lista donatori
            </mat-checkbox>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-label id="sr-amount" class="sr-only">Importo</mat-label>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="12em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label aria-hidden="true">Importo</mat-label>
            <input aria-labelledby="sr-amount" matInput formControlName="amount" required [matAutocomplete]="amountAuto">
            <span matSuffix>&nbsp;€</span>
            <mat-autocomplete #amountAuto="matAutocomplete">
              <mat-option value="">Importo libero</mat-option>
              <mat-option *ngFor="let option of amountOptions" [value]="option">
                {{option}} €
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="formErrors['amount']">{{ formErrors.amount }}</mat-error>
          </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
          <mat-card class="mat-elevation-z0" fxFlex="100%">
            <mat-card-content>
              <p><strong>INFORMATIVA RELATIVA AL TRATTAMENTO DEI DATI PERSONALI PER IL SERVIZIO: DONAZIONI PER EMERGENZA CORONAVIRUS tramite PagoPA.</strong></p>
              <p>&nbsp;</p>
              <p>In armonia con quanto previsto dal Regolamento Europeo sulla protezione dei dati personali 2016/679 (di seguito, anche <em>&ldquo;GDPR&rdquo;)</em> e dal D.Lgs. 30 giugno 2003, n. 196, come novellato dal D.Lgs. 10 agosto 2018, n. 101 - il cui obiettivo &egrave; quello di proteggere i diritti e le libert&agrave; fondamentali delle persone fisiche, con particolare riferimento al diritto alla protezione dei dati personali - &egrave; necessario che Lei prenda visione di una serie di informazioni che La possono aiutare a comprendere le motivazioni per le quali verranno trattati i Suoi dati personali, spiegandoLe quali sono i Suoi diritti e come li potr&agrave; esercitare.</p>
              <p><strong>Finalit&agrave; e base giuridica del trattamento dei dati personali</strong></p>
              <p>Ai sensi dell&rsquo;art. 13 del Regolamento 2016/679/UE, noto come GDPR <em>(General Data Protection Regulation)</em> si informa che i Suoi dati personali vengono acquisiti e trattati unicamente al fine di permetterLe di effettuare donazioni per l&rsquo;emergenza Coronavirus tramite PagoPA e di assicurare la corretta gestione dei relativi pagamenti, con utilizzo di procedure prevalentemente informatizzate e nei modi/limiti necessari per perseguire la predetta finalit&agrave;.</p>
              <p>Al servizio di cui si tratta &egrave; connesso il trattamento dei seguenti dati personali obbligatori:</p>
              <ul style="padding-left:20px">
              <li>nome</li>
              <li>cognome</li>
              <li>ragione sociale</li>
              <li>Codice Fiscale/Partita IVA</li>
              <li>indirizzo di posta elettronica</li>
              </ul>
              <p>La base giuridica del trattamento dei dati personali da Lei forniti risiede nel consenso espresso aisensi dell&rsquo;art. 6, co. 1, lett. a) del GDPR per le specifiche finalit&agrave; connesse all&rsquo;adesione al servizio <em>&ldquo;donazioni per emergenza coronavirus tramite PagoPA&rdquo;.</em></p>
              <p><strong>Modalit&agrave; del trattamento dei dati personali</strong></p>
              <p>Il trattamento &egrave; effettuato con l&rsquo;ausilio di mezzi elettronici o comunque automatizzati e trasmessi attraverso reti telematiche.</p>
              <p>Il Titolare adotta misure tecniche e organizzative adeguate a garantire un livello di sicurezza idoneo rispetto alla tipologia di dati trattati.</p>
              <p><strong>Titolare del trattamento</strong></p>
              <p>Titolare del trattamento &egrave; Regione del Veneto &ndash; Giunta Regionale con sede in Palazzo Balbi, Dorsoduro 3901, Cap. 30123 &ndash; Venezia.</p>
              <p><strong>Delegato al trattamento</strong></p>
              <p>Il Delegato al trattamento dei dati, ai sensi della DGR n. 596 del 08/05/2018 <em>(pubblicata sul BUR n. 44 del 11/05/2018)</em> &egrave; il Direttore della Direzione ICT e Agenda Digitale, con sede in Venezia Marghera, Complesso VEGA, Palazzo Lybra, Via Pacinotti n. 4.</p>
              <p>&nbsp;</p>
              <p><strong>Responsabile della protezione dei dati (RDP) &ndash; <em>Data Protection Officer</em></strong></p>
              <p>Il Responsabile della protezione dei dati (RPD) &ndash;<em> Data Protection Officer</em> presso Regione del Veneto &egrave; contattabile - per tutte le questioni relative al trattamento dei Suoi dati - al seguente indirizzo: <em>Cannaregio n. 168, Cap. 30121 - Venezia</em>, mail:<a href="mailto:dpo@regione.veneto.it"><em>dpo@regione.veneto.it</em></a>.&nbsp;</p>
              <p><strong>Comunicazione e diffusione dei dati personali</strong></p>
              <p>I Suoi dati personali sono trattati esclusivamente dal personale e da collaboratori di Regione del Veneto.&nbsp;</p>
              <p>Tali dati vengono trattati, nel ruolo di Responsabile del trattamento ex art. 28 del GDPR, dalla ditta Engineering Ingegneria Informatica S.p.A. <em>(C.F./P.IVA n. 00967720285)</em> la quale svolge attivit&agrave; di gestione e manutenzione dei sistemi informativi per conto di Regione Veneto.</p>
              <p>Alcune operazioni di trattamento possono essere altres&igrave; effettuate anche da altri soggetti terzi, ai quali Regione Veneto affida talune attivit&agrave;, o parte di esse, funzionali all'erogazione dei servizi. Su tali ulteriori soggetti, designati come Responsabili del trattamento, sono imposti da parte di Regione Veneto, mediante contratto o altro atto giuridico a norma del diritto dell&rsquo;UE o degli Stati membri, opportuni obblighi in materia di protezione dei dati personali attraverso istruzioni operative, con particolare riferimento all'adozione di misure tecniche ed organizzative adeguate, al fine di poter garantire la riservatezza e la sicurezza dei dati ex art. 32 del GDPR.</p>
              <p>Al di fuori delle predette ipotesi, i dati personali non saranno comunicati a terzi n&eacute; diffusi, se non nei casi specificamente previsti dal diritto nazionale o dell'Unione Europea.</p>
              <p><strong>Tempi di conservazione dei dati</strong></p>
              <p>I dati personali saranno conservati per un periodo di tempo di 10 anni, in conformit&agrave; ai termini diconservazione degli atti a fini fiscali.</p>
              <p><strong>Diritti degli interessati</strong></p>
              <p>Gli interessati (persone &ldquo;fisiche&rdquo; cui si riferiscono i dati) possono esercitare i diritti di cui agli artt. 15 e ss. del GDPR, ove applicabili. L&rsquo;apposita istanza &egrave; presentata contattando il succitato Data Protection Officer &ndash; Responsabile della protezione dei dati personali presso Regione del Veneto.</p>
              <p>Inoltre, gli interessati che ritengono che il trattamento dei dati personali a loro riferiti avvenga in violazione di quanto previsto dal Regolamento 2016/679/UE hanno diritto di proporre reclamo, ai sensi dell&rsquo;art. 77 del Regolamento 2016/679/UE, al Garante per la Protezione dei dati personali con sede in Piazza Venezia n. 11, Cap. 00187- ROMA (<em>email: <a href="mailto:protocollo@gpdp.it"><em>protocollo@gpdp.it</em></a>; PEC: <a href="mailto:protocollo@pec.gdpd.it"><em>protocollo@pec.gdpd.it</em></a>; centralino +39 06.696771)</em> ovvero di adire le opportune sedi giudiziarie (ex. Art. 79 del Regolamento)</p>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <div class="mat-form-field" fxFlex="100%">
            <mat-checkbox formControlName="privacy" required>
              Dichiaro di aver preso visione dell'informativa e di prestare il mio consenso al trattamento dei dati personali
            </mat-checkbox>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <button type="submit" mat-flat-button [disabled]="form.invalid" color="accent">
            <fa-icon [icon]="iconGift" size="lg" class="mr-1" alt="dona"></fa-icon> Dona ora
          </button>
        </div>

      </form>
    </div>

  </div>
</div>
