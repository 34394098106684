<div class="container" fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center"
  fxLayoutGap="10px">

  <div class="title-row">
    <h1 class="mat-h1 bold">
      <fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}
    </h1>
  </div>

  <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

  <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
    <mat-card-content>
      <div>
        <h1>
          <p class="text-center">INFORMATIVA SUI COOKIES</p>
        </h1>
        <p>Un “cookie” è un piccolo file di testo creato sul computer dell'utente al momento in cui questo accede ad un
          determinato sito, con lo scopo di immagazzinare e trasportare informazioni.
          I cookie sono inviati da un server web (che è il computer sul quale è in esecuzione il sito web visitato) al
          browser dell'utente (Internet Explorer, Mozilla Firefox, Google Chrome, ecc.) e memorizzati sul computer di
          quest'ultimo; vengono, quindi, re-inviati al sito web al momento delle visite successive.
          Nel corso della navigazione l’utente potrebbe ricevere sul suo terminale anche cookie di siti diversi (c.d.
          cookies di “terze parti”), impostati direttamente da gestori di detti siti web e utilizzati per le finalità e
          secondo le modalità da questi definiti
        </p>

        <h2>
          <p>Cookie del titolare</p>
        </h2>
        <p>
          Il portale MyPay utilizza alcuni cookie tecnici, rispetto ai quali, ai sensi dell'art. 122 del codice
          privacy e del
          Provvedimento del Garante dell’8 maggio 2014, non è richiesto alcun consenso da parte dell'interessato.
          <br>
          Nessun dato personale degli utenti viene in proposito acquisito dal sito.
          <br>
          Non viene fatto uso di cookies per la trasmissione di informazioni di carattere personale, né vengono
          utilizzati c.d.
          cookies persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti.
          <br>
          L'uso di c.d. cookies di sessione (che non vengono memorizzati in modo persistente sul computer dell'utente e
          svaniscono con la chiusura del browser) è strettamente limitato alla trasmissione di identificativi di
          sessione
          (costituiti da numeri casuali generati dal server) necessari per consentire l'esplorazione sicura ed
          efficiente del
          sito.
          <br>
          I c.d. cookies di sessione utilizzati in questo sito evitano il ricorso ad altre tecniche informatiche
          potenzialmente
          pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati
          personali
          identificativi dell'utente.
          <br><br>


          I cookies utilizzati da MyPay sono i seguenti:
        </p>
        <ul>
          <li>cookieLawSeen: scadenza in 5 anni. Utilizzato per salvare l'accettazione della cookie law policy. Appare
            dopo
            aver
            espresso il consenso sull'utilizzo di cookie.</li>
          <li>cookiePoll_*: scadenza parametrizzata per ogni singolo sondaggio. Utilizzato per tracciare le risposte
            dell'utente ai
            sondaggi pubblicati sul portale per limitare il numero di risposte nel tempo.</li>
          <li>
            LBLSESSIONID: scadenza al termine della sessione di navigazione. Utilizzato per session affinity sul load
            balancer
            regionale.</li>
          <li>
            JSESSIONID: scadenza al termine della sessione di navigazione. Utilizzato per mantenere attiva la sessione
            dell'utente
            sull'application server.</li>
          <li>
            REGIONEcookieID: ha una vita di circa 10 millisecondi e viene generato ad ogni nuova apertura di una nuova
            pagina del
            sito. Viene iniettato nel browser ad ogni nuovo ingresso negli LBL per poter tracciare univocamente gli
            ingressi che
            altrimenti potrebbero essere falsati da Proxy o Nat e non permetterebbero una corretta tracciatura degli ip
            sorgenti.</li>
          <li>
            csrf-token: scadenza di sessione. Utilizzato per la mitigazione/prevenzione degli attacchi di tipo XSRF.
          </li>
          <li>
            autentication*: scadenza di un giorno. Utilizzato per identificare la sessione utente.</li>
          <li>
            LBLSESSIONID: scadenza al termine della sessione di navigazione. Utilizzato per session affinity sul load
            balancer
            regionale.</li>
          <li>
            JSESSIONID: scadenza al termine della sessione di navigazione. Utilizzato per mantenere attiva la sessione
            dell'utente
            sull'application server.</li>
          <li>
            REGIONEcookieID: ha una vita di circa 10 millisecondi e viene generato ad ogni nuova apertura di una nuova
            pagina del
            sito. Viene iniettato nel browser ad ogni nuovo ingresso negli LBL per poter tracciare univocamente gli
            ingressi che
            altrimenti potrebbero essere falsati da Proxy o Nat e non permetterebbero una corretta tracciatura degli ip
            sorgenti.</li>
          <li>
            jwtToken: scadenza al termine della sessione di navigazione. Utilizzato per mantenere attiva la sessione
            dell'utente
            sull'application server.</li>
        </ul>


        <h2>Cookie di terze parti</h2>
        <p>

          La validità dell’informativa contenuta nella presente pagina è limitata al solo Portale MyPay e non si estende
          ad altri siti web eventualmente consultabili mediante collegamento ipertestuale.

          Si riportano nel dettaglio i cookie di terze parti che potrebbero essere utilizzati dal Portale MyPay, nonché
          i link attraverso i quali l’utente può ricevere maggiori informazioni e richiedere la disattivazione dei
          cookie.</p>


        <h3>Google Re Captcha</h3>

        <p> Per garantire la sicurezza dei sistemi preposti al funzionamento del Portale, la sezione “Altre tipologie di
          pagamento” utilizza sistemi di tracciamento di tipo test CAPTCHA. Tali sistemi consentono ai gestori di pagine
          web di distinguere tra accesso umano e automatico (es. bot) ai siti web, proteggendoli da attività di spam e
          altre attività illecite. In particolare, il Sito utilizza il sistema _GRECAPTCHA, offerto da Google, il quale
          funziona raccogliendo informazioni hardware e software, come i dati del dispositivo e dell’applicazione, e
          inviando questi dati a Google per l’analisi. Le informazioni raccolte in relazione all’uso di _GRECAPTCHA da
          parte degli Interessati sono altresì utilizzati per migliorare il test CAPTCHA e per scopi di sicurezza
          generali. Queste informazioni non saranno mai utilizzate per la pubblicità personalizzata da Google. Per
          maggiori informazioni, è possibile consultare l’informativa privacy e i termini e condizioni d’uso predisposti
          da Google..

          Questi dati sono trattati al fine di garantire la sicurezza del sistema. La base giuridica del trattamento è
          l'esecuzione del Servizio da te richiesto.

        </p>

        <h3> Matomo</h3>

        <p>
          Matomo è una piattaforma web di analisi dei dati di quanto e come gli utenti utilizzano i siti web. I dati
          sono raccolti in forma anonima.
          <br>
          L'informativa e i termini di servizio sono reperibili al seguente link:
          <br><br>
          <a href="https://matomo.org/faq/general/faq_146/" target="_blank">https://matomo.org/faq/general/faq_146/</a>
          <br><br>
          <a href="https://matomo.org/faq/general/faq_18254/"
            target="_blank">https://matomo.org/faq/general/faq_18254/</a>
          <br><br>
          Per la disattivazione/cancellazione/gestione, fare riferimento al successivo paragrafo <i>"Disabilitazione dei
            cookie"</i>
        </p>


        <h2>Disabilitazione dei cookie</h2>

        <p>Fermo restando quanto sopra indicato in ordine ai cookie strettamente necessari alla navigazione, l’utente
          può eliminare gli altri cookie attraverso la funzionalità a tal fine messa a disposizione dal Titolare tramite
          la presente informativa oppure direttamente tramite il proprio browser.<br>

          Ciascun browser presenta procedure diverse per la gestione delle impostazioni. L’utente può ottenere
          istruzioni specifiche attraverso i link sottostanti.<br><br>
        </p>
        <ul>
          <li><a href="https://support.google.com/chrome/bin/answer.py?hl=it&answer=95647&p=cpn_cookies"
              target="_blank">Google Chrome</a></li>
          <li><a href="http://support.mozilla.org/it-IT/kb/Enabling%20and%20disabling%20cookies" target="_blank">Mozilla
              Firefox</a></li>
          <li><a href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank">Microsoft Windows Explorer</a></li>
          <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
          <li><a href="http://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT" target="_blank">Apple
              Safari</a></li>
        </ul>
        <br><br>
        <p>La disattivazione dei cookie di terze parti è inoltre possibile attraverso le modalità rese disponibili
          direttamente dalla società terza titolare per detto trattamento, come indicato ai link riportati nel paragrafo
          “Cookie di terze parti”.
        </p>

      </div>
    </mat-card-content>
  </mat-card>

</div>