<div class="container">
  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div class="w100 mypay-search-form" aria-level="1">
      <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
        <p class="my-5">{{esito}}</p>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
        <button type="button" mat-flat-button color="accent" (click)="backHome()"> Torna alla Homepage </button>
      </div>
    </div>


  </div>
</div>
