<div
    class="container"
    fxFlex
    fxLayout="column"
    fxLayout.gt-sm="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="10px">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-content>

        <h1>
          <p class="text-center">INFORMATIVA PER IL TRATTAMENTO DEI DATI PERSONALI</p>
        </h1>
        
        <h3>
          <p class="text-center">(art. 13, Regolamento 2016/679/UE - GDPR)</p>
        </h3>

        <p>
          In base al Regolamento 2016/679/UE (General Data Protection Regulation - GDPR) "ogni persona ha diritto alla protezione dei dati di carattere personale che la riguardano". 
          I trattamenti di dati personali sono improntati ai principi di correttezza, liceità e trasparenza, tutelando la riservatezza dell'interessato e i suoi diritti.
          <br><br>
          La presente informativa descrive le modalità del trattamento dei dati personali degli utenti (di seguito, anche gli "Utenti") che utilizzano 
          il Portale dei Pagamenti della Regione del Veneto (di seguito Regione).
        </p>
      
        <br/><br/>

        <h2>Finalità e base giuridica del trattamento</h2>
        <div>
          <p>
            Il Portale dei Pagamenti della Regione del Veneto è nato per consentire a cittadini e imprese di effettuare pagamenti elettronici 
            a favore della Regione, secondo i modelli previsti da PagoPA. Il Portale è stato successivamente reso disponibile, in modalità di adesione, 
            ai soggetti del territorio regionale (Enti Locali, Pubbliche Amministrazioni, altro), per consentire a cittadini e imprese di effettuare pagamenti 
            elettronici a favore degli Enti aderenti stessi.
            <br><br>
            La base giuridica del trattamento è il Decreto Legislativo n. 82/2005 (Codice dell'Amministrazione Digitale, di seguito CAD), i DPCM 
            di approvazione annuale del Piano Triennale per l'informatica nella PA e la Legge Regionale n. 44 del 25.11.2019, al fine favorire la conoscenza, 
            lo sviluppo e la diffusione degli strumenti per l'attuazione dell'agenda digitale.
            <br><br>
            La base giuridica e limiti del trattamento sono da rinvenirsi inoltre nei compiti esclusivamente istituzionali, di interesse pubblico o per gli adempimenti previsti 
            da norme di legge o di regolamento, affidati a Regione del Veneto e agli Enti Locali, al fine di erogare il Servizio medesimo e/o rispondere alle istanze presentate.
            <br><br>
            I dati raccolti mediante il Portale Pagamenti sono trattati in formato aggregato, per finalità statistiche.
            <br><br>
            Specifiche finalità, relative ai singoli trattamenti, potranno essere segnalate in maniera dettagliata nell'ambito dei vari canali di accesso. 
            All'interno di essi l'Utente potrà trovare informazioni integrative sul trattamento dei dati personali.
          </p>
        </div>
        
        <br/><br/>

        <h2>Titolari e Responsabili del trattamento</h2>
        <div>
          <h3>Regione del Veneto</h3>
          <p>
            Limitatamente ai dati personali necessari per la gestione di pagamenti a favore della Regione del Veneto, Titolare del 
            trattamento è Regione del Veneto - Giunta Regionale, con sede in Venezia, Palazzo Balbi, Dorsoduro Cap. 3901 - CF e P.IVA 02392630279.
            <br>
            Delegati al trattamento di tali dati, ai sensi della DGR n. 596 del 08/05/2018 (pubblicata sul BUR n. 44 del 11/05/2018) sono:
            <li>il Direttore della Struttura Regionale preposta alla gestione dell'accertamento delle entrate competente per materia.<br>
            <li>il Direttore della Direzione Bilancio e Ragioneria per la gestione generale della Contabilità delle entrate e rendicontazione e dell'Archivio dati anagrafici.<br> 
            <li>il Direttore della Direzione ICT e Agenda Digitale per la parte tecnica.<br>
            <br>
            Vengono a conoscenza dei dati le persone autorizzate al trattamento delle rispettive Direzioni.<br> 
            I dati saranno comunicati per via telematica alla banca che svolge il Servizio di Tesoreria Regionale che è preposta alla riscossione delle 
            somme dei pagamenti effettuati, come previsto dagli artt. 38 e 39 della L.R. n. 39/2001.
          </p>				
          <br/>
          <h3>Enti aderenti</h3>
          <p>
            Limitatamente ai dati personali necessari per la gestione di pagamenti a favore dei soggetti aderenti al Portale dei Pagamenti della Regione, 
            ognuno dei suddetti soggetti è Titolare del trattamento ai sensi del Regolamento UE 2016/679, rispettivamente per gli adempimenti di propria competenza. 
            Informativa ai sensi art. 13, Regolamento 2016/679/UE - GDPR, riferimenti e contatti del DPO degli Enti aderenti sono rinvenibili presso gli Enti Creditori stessi.
            <br><br>
            Per tali Enti il Responsabile Esterno del trattamento è Regione del Veneto - Direzione ICT e Agenda Digitale.
          </p>
        </div>
        
        <br/><br/>

        <h2>Categorie di dati trattati</h2>
        <div>
          <h3>a) Dati di navigazione</h3>
          <p>
            I sistemi informatici e le procedure software preposte al funzionamento dei prodotti/piattaforme messi a disposizione acquisiscono, 
            nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet. 
            In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer e dei terminali utilizzati dagli utenti, gli indirizzi in 
            notazione URI/URL (Uniform Resource Identifier/Locator) delle risorse richieste, l'orario della richiesta, il metodo utilizzato nel sottoporre 
            la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server 
            (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all'ambiente informatico dell'utente.
            <br><br>
            Tali dati, necessari per la fruizione dei servizi web, sono trattati anche allo scopo di:
            <li>ottenere informazioni statistiche sull'uso dei servizi (pagine più visitate, numero di visitatori per fascia oraria o giornaliera, aree geografiche di provenienza, ecc.);
            <li>controllare il corretto funzionamento dei servizi offerti.
          </p>
          <br>
          <h3>b) Dati forniti volontariamente dall'Utente</h3>
          <p>
            In fase di fruizione del Portale Pagamenti della Regione del Veneto, gli Utenti forniscono volontariamente alcuni dati personali.
            Tali dati, richiesti come obbligatori o facoltativi, sono trattati esclusivamente ai fini dell'identificazione dell'Utente e per le finalità del trattamento 
            indicate al secondo paragrafo della presente informativa.
            <br>
            L'invio di posta elettronica agli indirizzi indicati nei differenti canali di accesso del portale e la compilazione dei "format" (maschere) specificamente predisposti, 
            comportano la successiva acquisizione dell'indirizzo di posta e/o dei dati del mittente/utente, necessari per rispondere alle istanze presentate e/o erogare il servizio richiesto.
          </p>
        </div>
        
        <br/><br/>

        <h2>Facoltatività del conferimento dei dati e conseguenza dell'eventuale rifiuto</h2>
        <div>
          <p>
            L'Utente è libero di fornire i dati personali riportati nei moduli di richiesta dei vari servizi offerti nel portale, ma il mancato 
            conferimento dei dati richiesti comporterà l'impossibilità di ottenere il servizio offerto mediante il Portale dei Pagamenti della Regione del Veneto
          </p>
        </div>
    
        <br/><br/>
        
        <h2>Categorie di soggetti ai quali i dati personali possono essere comunicati e finalità della comunicazione</h2>
        <div>
          <p>
            Il Titolare potrà comunicare, per il perseguimento delle finalità sopra indicate, alcuni dati personali anche a soggetti terzi 
            che forniscono un servizio al titolare stesso e che tratteranno detti dati personali in qualità di responsabili del trattamento.
            <br><br>
            Al di fuori delle ipotesi summenzionate, i dati non saranno comunicati a terzi soggetti nè diffusi, se non nei casi specificamente 
            previsti dal diritto nazionale o dell'Unione Europea. 
            <br><br>
            I dati trattati non sono in nessun caso diffusi.
          </p>
        </div>
    
        <br/><br/>



        <h2>Modalità del trattamento e durata della conservazione dei dati</h2>
        <div>
          <p>
            I dati personali forniti dall'Utente sono trattati con modalità informatizzate e saranno conservati in conformità alle norme 
            sulla conservazione della documentazione amministrativa e comunque per il tempo necessario a raggiungere le finalità istituzionali, 
            ivi comprese quelle di archiviazione nel pubblico interesse, ricerca scientifica o storica o a fini statistici. 
          </p>
        </div>
        
        <br/><br/>
        
        <h2>Diritti degli interessati</h2>
        <div>
          <p>
            Gli interessati hanno il diritto di ottenere, nei casi previsti, l'accesso ai propri dati personali e la rettifica o la cancellazione 
            degli stessi o la limitazione del trattamento che li riguarda o di opporsi al trattamento (artt. 15 e ss. del Regolamento). 
          </p>
        </div>		
        
        <br/><br/>
        
        <h2>Diritti di reclamo</h2>
        <div>
          <p>
            Gli interessati che ritengono che il trattamento dei dati personali a loro riferiti effettuato attraverso questo sito avvenga in 
            violazione di quanto previsto dal Regolamento hanno il diritto di proporre reclamo al Garante, come previsto dall'art. 77 del 
            Regolamento stesso, o di adire le opportune sedi giudiziarie (art. 79 del Regolamento), con sede in Piazza Venezia n. 11 - Cap. 00187 Roma.
          </p>
        </div>		
        
        <br/><br/>
        
        <h2>Contatti</h2>
        <div>
          <p>
            Per questioni relative alla privacy, il <i>Data Protection Officer</i> (DPO) di Regione del Veneto può essere contattato al seguente recapito PEC: 
            <a href="mailto:dpo@pec.regione.veneto.it" target="_blank">dpo@pec.regione.veneto.it</a> 
            o scrivendo a Regione del Veneto, DPO, Palazzo Sceriman, Cannaregio n. 168 - Cap. 30121 Venezia.
            <br><br>
            Per <b>problemi tecnici</b>,  relativi al portale MyPay, invece è possibile scrivere una email a
            <a href="mailto:call.center@regione.veneto.it" target="_blank">call.center@regione.veneto.it</a> mettendo come oggetto: "MyPay richiesta di supporto". 
            Nel corpo dell'email riportare cosa si vuole pagare, l'ente e il motivo della segnalazione, inserendo eventuali screenshot che possano aiutare a capire la richiesta.
          </p>
        </div>		



      </mat-card-content>
    </mat-card>

</div>
