<button type="button" aria-label="Help online del campo" title="Clicca qui per visualizzare l’help" mat-icon-button (click)="openHelp(helpdialog)" [attr.aria-label]="'Help'">
  <fa-icon [icon]="iconQuestionCircle"></fa-icon>
</button>

<ng-template #helpdialog>
  <div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
    <h2>Help online</h2>
    <span class="flex-spacer"></span>
    <button mat-flat-button mat-dialog-close><fa-icon [icon]="iconTimes" size="lg"></fa-icon></button>
  </div>

  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
  </mat-dialog-content>
</ng-template>
