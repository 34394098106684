import { Directive } from '@angular/core';
import { LayoutDirective } from '@angular/flex-layout';

const selector = `[fxLayout.gt-bsmd],[fxLayout.lt-bsmd],[fxLayout.bsmd]`;
const inputs = ['fxLayout.gt-bsmd','lt-bsmd','bsmd'];

@Directive({selector, inputs})
export class CustomLayoutDirective extends LayoutDirective {
  protected inputs = inputs;
}
