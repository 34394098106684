import { BehaviorSubject } from 'rxjs';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'detailfilter',
    pure: false
})
export class DetailFilterPipe implements PipeTransform {
    transform(items: any[] | BehaviorSubject<any[]>, include:string[], exclude:string[]): any {
        if (!items || !(include || exclude)) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        let newItems: any[];
        if(items instanceof BehaviorSubject)
          newItems = items.value;
        else
          newItems = items;
        if(include)
          newItems = newItems.filter(item => !item.id || include.includes(item.id));
        if(exclude)
          newItems = newItems.filter(item => !item.id || !exclude.includes(item.id));
        return newItems;
    }
}
