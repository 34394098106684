import Dexie from 'dexie';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DexieService extends Dexie {

  public static THUMB_LOGO_TABLE = "thumbLogoTable";

  constructor() {
    super('MyPay4');
    const tables = {};
    tables[DexieService.THUMB_LOGO_TABLE] = '';
    this.version(1).stores(tables);
  }
}
