
export class Nazione {
  nazioneId: number;
  nomeNazione: string;
  codiceIsoAlpha2: string;
  constructor(nazioneId: number){
    this.nazioneId = nazioneId;
  }
}

export class Provincia {
  provinciaId: number;
  provincia: string;
  sigla: string;
  constructor(provinciaId: number){
    this.provinciaId = provinciaId;
  }
}

export class Comune {
  comuneId: number;
  comune: string;
  provinciaId: number;
  //siglaProvincia: string;
  //codBelfiore: string;
  //codiceIstat: string;
  //varCodBelfiore: string;
  //varProvincia: string;
  //varComune: string;
  constructor(comuneId: number){
    this.comuneId = comuneId;
  }
}
