<div
    class="container"
    fxFlex
    fxLayout="column"
    fxLayout.gt-sm="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="10px">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-subtitle><b>Leggi le domande frequenti</b></mat-card-subtitle>
      <mat-card-content>
        <div>
          <p>Abbiamo provato a dare risposta alle domande più frequenti relative alle diverse situazioni che si possono verificare durante un pagamento.</p>
          <p><a href="https://myextranet.regione.veneto.it/myportal/MYEXT/api/download/faq_mypay_cittadino">Link a FAQ</a></p>
        </div>
      </mat-card-content>
    </mat-card>

</div>
