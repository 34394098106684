import { Component, OnInit } from '@angular/core';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  get titleLabel(){ return "Assistenza" }
  get titleIcon(){ return faConciergeBell }

  constructor() { }

  ngOnInit(): void {
  }

}
