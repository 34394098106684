<div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
  <h2>{{titleLabel}}</h2>
</div>

<mat-dialog-content>
  <div [innerHtml]="htmlContent"></div>
  <mat-checkbox *ngIf="checkboxEnabled" [(ngModel)]="checkboxState">{{checkboxLabel}}</mat-checkbox>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="manageClose(false)" color="accent">{{cancelLabel}}</button>
  <button mat-flat-button (click)="manageClose(true)" [disabled]="invalid" color="accent">{{confirmLabel}}</button>
</mat-dialog-actions>
