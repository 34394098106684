import { ConfigurationFactory, environment } from 'projects/mypay4-fe-common/src/public-api';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

ConfigurationFactory.get().init()
.then(() => {
  if (environment.production)
    enableProdMode();
  return platformBrowserDynamic()
    .bootstrapModule(AppModule);
})
.catch(bootstrapManageError);

function bootstrapManageError(error:any){
    // error should be logged into console by defaultErrorLogger, so no extra logging is necessary here
    // console.log(err);
    // show error to user
    const errorMsgElement = document.querySelector('#errorMsgElement');
    let message = 'Errore di sistema avviando l\'applicazione. Si prega di riprovare in seguito.';
    if (error) {
        if (error.message) {
            message = message + ' Causa dell\'errore: ' + error.message;
        } else {
            message = message + ' Causa dell\'errore: ' + error;
        }
    }
    if(errorMsgElement)
        errorMsgElement.textContent = message;
    const initialLoader: HTMLElement = document.querySelector('#initialLoader');
    if(initialLoader)
        initialLoader.style.display = 'none';
}
