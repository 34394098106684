import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ApiInvokerService } from '../../public-api';
import { Mappers } from '../mapper/mappers';
import { User } from '../model/user';
import { ConfigurationFactory } from '../utils/backend-configuration-factory';

declare const myPayAppModuleName: any;

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private backendConfigurationFactory: ConfigurationFactory;
  private userFromCookie: User;
  private mapQueryFlags = new Map<string, string>();

  constructor(
    private httpBackend: HttpBackend,
    private route: ActivatedRoute,
  ) {
    this.backendConfigurationFactory = ConfigurationFactory.get();
    console.log(document.location);
    const queryParams = new URL(document.location.href).searchParams;
    queryParams.forEach((value, key) => {
      if(key.startsWith('flag_')){
        this.mapQueryFlags.set(key.substring(5), value);
        console.log('add flag param '+key.substring(5)+': '+value);
      }
    });

  }

  bootstrapConfig(): Promise<void> {
    const useAuthCookie = this.getBackendProperty<boolean>('useAuthCookie', false);
    if(useAuthCookie){
      return new HttpClient(this.httpBackend)
      .post<User>(this.getProperty('baseApiUrl')+'checkLoginCookie', null)
      .toPromise().then(user => {
        if(user && typeof user === 'object')
          this.userFromCookie = ApiInvokerService.mapperFunction(user, new Mappers({mapperS2C:User}), 'S2C');
        return;
      }, error => {
        console.log('error checkLoginCookie, ignoring it!', error);
      });
    } else
      return Promise.resolve();
  }

  getBackendProperty<T = string>(key: string, defaultValue?: T):T {
    return this.backendConfigurationFactory.getBackendProperty(key, defaultValue);
  }

  getProperty<T = string>(key: string, appEnvironment?:object, defaultValue?: T):T {
    return this.backendConfigurationFactory.getProperty(key, appEnvironment, defaultValue);
  }

  getUserFromCookie():User {
    return this.userFromCookie;
  }

  getMyPayAppModuleName(): string {
    return myPayAppModuleName;
  }

  getFlag(key: string, defaultValue?: string):string {
    return this.mapQueryFlags.get(key) ?? defaultValue;
  }

}
