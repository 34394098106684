import { Component } from '@angular/core';

@Component({
  selector: 'app-overlay-spinner-container',
  templateUrl: './overlay-spinner-container.component.html',
  styleUrls: ['./overlay-spinner-container.component.css']
})
export class OverlaySpinnerContainerComponent {

  constructor() { 
    //This is intentionally empty
  }
  
}
