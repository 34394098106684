import { DateTime } from 'luxon';

import { MapperDef, MapperType } from '../mapper/mapper-def';
import { WithActions } from '../table/with-actions';

export class TipoDovuto extends WithActions {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.Function,'statoAbilitazione',(tipoDovuto: TipoDovuto) => {
      return tipoDovuto.flgAttivo ? 'Abilitato' : 'Disabilitato';
    }),
    new MapperDef(MapperType.DateTime,'dtUltimaAbilitazione','local-date-time'),
    new MapperDef(MapperType.DateTime,'dtUltimaDisabilitazione','local-date-time'),
  ]
  mygovEnteTipoDovutoId: number;
  codIpaEnte: string;
  codTipo: string;
  deTipo: string;
  deUrlPagamentoDovuto: string;

  //for operatore
  mygovEnteId: number;
  deNomeEnte: string;
  thumbLogoEnte: string;
  flgCfAnonimo: boolean;
  flgScadenzaObbligatoria: boolean;
  flgAttivo: boolean;
  importo: string;

  statoAbilitazione: string;
  dtUltimaAbilitazione: DateTime;
  dtUltimaDisabilitazione: DateTime;
}
