import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export class Breadcrumb {

  public originalLabel: string;
  public originalIcon: IconDefinition;

  constructor (
    public label?: string,
    public icon?: IconDefinition,
    public home: boolean = false,
  ) {};
}
