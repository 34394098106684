import * as _ from 'lodash';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map'
})
export class MapPipe implements PipeTransform {
  transform<T>(input:T, data:Map<T,any>|{}, nullValue?: string): any {
    if(_.isNil(input))
      return nullValue || '';
    if(Array.isArray(input)){
      return input.map(elem => (data instanceof Map ? data.get(elem) : data[elem]) || elem);
    } else {
      return (data instanceof Map ? data.get(input) : data[input as unknown as string]) || input;
    }
  }
}
