<div
    class="container"
    fxFlex
    fxLayout="column"
    fxLayout.gt-sm="row wrap"
    fxLayoutAlign="start center"
    fxLayoutGap="10px">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

    <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
      <mat-card-content>
        <div>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus imperdiet, ex non auctor consequat, turpis erat consequat diam, vitae ultrices ligula magna sit amet urna. Suspendisse id ultricies risus. Proin vulputate faucibus purus non facilisis. In non porttitor leo. Curabitur felis turpis, maximus vel justo ac, sodales iaculis diam. In congue bibendum diam vel scelerisque. Praesent eget mattis ante. Sed placerat congue suscipit. Ut in lorem et odio luctus convallis ac eu orci. Nullam varius, eros at porta lobortis, tellus velit molestie urna, vitae finibus ligula nibh eu quam.</p>
          <p>Nullam eu massa interdum, tempor leo vel, ultrices nisl. Sed a viverra nisl. Suspendisse vel aliquet augue. Quisque varius ornare consequat. Aenean molestie hendrerit purus, vel pulvinar ligula porta quis. Praesent dapibus quam vitae fermentum consectetur. Etiam condimentum magna non nulla commodo, a facilisis mauris condimentum. Proin rutrum neque in finibus suscipit.          </p>
          <p>Donec pulvinar efficitur augue. In viverra, ante mattis fermentum congue, justo mauris pharetra lectus, in egestas lacus est in massa. Integer vel risus justo. Mauris vel odio vehicula, pellentesque nisi quis, sagittis est. Vivamus mauris lorem, molestie in ornare quis, eleifend quis ex. Aliquam tincidunt eget sapien in lacinia. Cras maximus neque ut consectetur euismod. Duis congue magna ut mi fermentum, ultrices mollis enim dictum.          </p>
          <p>Aliquam tempus metus augue, sit amet condimentum neque maximus ac. Aliquam dapibus sodales urna, eget egestas tortor bibendum ut. In dolor enim, pharetra nec tincidunt vitae, mattis vel metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Ut suscipit arcu ut nisl accumsan, eget pellentesque velit maximus. Morbi at elit neque. Proin aliquam maximus mi. Nam tempor tristique vestibulum. Praesent a orci suscipit, malesuada nibh et, blandit mauris. Suspendisse potenti. Integer id magna luctus, eleifend mi in, iaculis mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vel rutrum justo. Etiam sit amet feugiat erat. Etiam cursus in mi non fermentum.</p>
          <p>Nam maximus, sem ac eleifend hendrerit, ligula velit commodo justo, ut auctor dolor orci et mi. Donec sapien lectus, gravida ut pharetra ac, mollis eu risus. Curabitur malesuada lacinia odio in scelerisque. Duis at ultricies neque. Nam non consectetur diam. Nunc et tempus mi. Maecenas vel nulla lacus.</p>
        </div>
      </mat-card-content>
    </mat-card>

</div>
