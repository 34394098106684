import { ApiInvokerService, BaseUrlService, User } from 'projects/mypay4-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtenteService {

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService: ApiInvokerService,
  ) { }

  update(utente: User): Observable<void> {
    const targetUrl = this.baseUrlService.getCittadinoUrl() + 'utente';
    return this.apiInvokerService.post<void>(targetUrl, utente);
  }

}
