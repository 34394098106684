import { Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
name: 'dynamicPipe'
})
export class DynamicPipe implements PipeTransform {

  public constructor(private injector: Injector) {
  }

  transform(value: any, pipeToken: any, pipeArgs: any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get<PipeTransform>(pipeToken as Type<PipeTransform>);
      try{
        return pipe.transform(value, ...(pipeArgs ?? []));
      } catch(error){
        console.warn(`Dynamic pipe [${pipeToken?.constructor?.name}] error on value [${value}]: ${error}`);
        return value;
      }
    }
  }
}
