/** Get a nested property from an object without returning any errors.
 * If the property or property chain doesn't exist, undefined is returned.
 * Property names with spaces may use either dot or bracket "[]" notation.
 * Note that bracketed property names without surrounding quotes will fail the lookup.
 *      e.g. embedded variables are not supported.
 * @param {object} obj The object to check
 * @param {string} prop The property or property chain to get (e.g. obj.prop1.prop1a or obj['prop1'].prop2)
 * @returns {*|undefined} The value of the objects property or undefined if the property doesn't exist
 */
 export function getProp(obj, prop) {
  if (typeof obj !== 'object') throw 'getProp: obj is not an object'
  if (typeof prop !== 'string') throw 'getProp: prop is not a string'

  // Replace [] notation with dot notation
  prop = prop.replace(/\[["'`](.*)["'`]\]/g,".$1")

  return prop.split('.').reduce(function(prev, curr) {
      return prev ? prev[curr] : undefined
  }, obj || self)
}

export function generateRandomUUID() {
  let uuid:string;
  try{
    uuid = self.crypto['randomUUID']();
  } catch (error) {
    console.log('error using crypto.randomUUID, trying fallback implementation: '+error);
    // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  console.debug('generateRandomUUID: ', uuid);
  return uuid;
}