import { first } from 'rxjs/operators';

import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

@Directive({
  selector: '[app-confirm]'
})
export class AppConfirmDirective {
  @Output("clickConfirmed") clickConfirmed: EventEmitter<any> = new EventEmitter();

  message: string;
  invalid: boolean = false;
  confirmLabel: string;
  cancelLabel: string;

  @Input('app-confirm') set setMessage(message: string | {message: string, invalid: boolean, confirmLabel?: string, cancelLabel?: string}){
    if(typeof message == 'string'){
      this.message = message;
      this.invalid = false;
    }else{
      this.message = message.message;
      this.invalid = message.invalid;
      this.confirmLabel = message.confirmLabel;
      this.cancelLabel = message.cancelLabel;
    }
  }

  constructor(private dialog: MatDialog) { }

  @HostListener('click', ['$event'])
  onClick(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.dialog.open(ConfirmDialogComponent,{autoFocus:false, data: {message: this.message,
      invalid: this.invalid, confirmLabel: this.confirmLabel, cancelLabel: this.cancelLabel}})
    .afterClosed().pipe(first()).subscribe(result => {
      if(result==="true")
        this.clickConfirmed.next(e);
    });
  }
}
