import { Injectable } from '@angular/core';

import { ApiInvokerService } from './api-invoker.service';
import { BaseUrlService } from './base-url.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private baseUrlService: BaseUrlService,
    private apiInvokerService:ApiInvokerService) { }

  public getObject<T>(tokenId: string) {
    return this.apiInvokerService.get<T>(this.baseUrlService.getPubCittadinoUrl()+'storage/object/'+tokenId);
  }
}
