interface IMappers {
  mapper?: new () => any
  mapperC2S?: new () => any
  mapperS2C?: new () => any
  responseMapper?: (resp:any) => any
}

export class Mappers {
  mapper?: new () => any
  mapperC2S?: new () => any
  mapperS2C?: new () => any
  responseMapper?: (resp:any) => any

  constructor(values?: IMappers) {
    Object.assign(this, values);
  }
}
