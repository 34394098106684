import { environment } from 'projects/mypay4-fe-cittadino/src/environments/environment';
import {
    ConfigurationService, MenuItem, MyPayBreadcrumbsService, UserService
} from 'projects/mypay4-fe-common/src/public-api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    faBars, faBell, faEllipsisH, faSignInAlt, faSignOutAlt, faTimes
} from '@fortawesome/free-solid-svg-icons';

import { MenuService } from '../../services/menu.service';
import { SidenavService } from '../../services/sidenav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  iconBars = faBars;
  iconBell = faBell;
  itemSignOut = faSignOutAlt;
  iconHeader = faEllipsisH;
  iconSignIn = faSignInAlt;
  classHeader = '';

  onClickButtonHeader() {
    if(this.iconHeader == faEllipsisH){
      this.iconHeader = faTimes;
      this.classHeader = 'is-open';
    } else {
      this.iconHeader = faEllipsisH;
      this.classHeader = null;
    }
  }

  notifications: string[];

  logged: boolean = false;
  secondLevelMenu:MenuItem[] = null;

  baseMyPAUrl: string;

  private loggedUserSub: Subscription;

  constructor(
    public userService: UserService,
    public menuService: MenuService,
    public myPayBreadcrumbsService: MyPayBreadcrumbsService,
    private sidenav: SidenavService,
    private router: Router,
    conf: ConfigurationService,) { 
      this.baseMyPAUrl = conf.getProperty('baseMyPAUrl', environment);
    }

  ngOnInit(): void {
    // manage the configuration and set the second level menu
    const routerSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        const url = (<NavigationEnd>event).urlAfterRedirects || (<NavigationEnd>event).url;
        const firstLevelMenuItem = this.menuService.getFirstLevelMenuItemByUrl(url) || this.menuService.getDefaultFirstLevelMenuItem();
        if(firstLevelMenuItem){
          this.menuService.setSelectedMenuItemFirstLevel(firstLevelMenuItem);
          this.secondLevelMenu = this.menuService.getMenuSecondLevel(this.logged);
          routerSub.unsubscribe();
        }
      });
    this.logged = this.userService.isLogged();
    this.loggedUserSub = this.userService.getLoggedUserObs().subscribe(user => {
      this.logged = user!=null;
      this.secondLevelMenu = this.menuService.getMenuSecondLevel(this.logged);
    });
  }

  ngOnDestroy():void {
    this.loggedUserSub?.unsubscribe();
  }

  openLoginForm() {
    this.userService.goToLogin();
  }

  logout() {
    this.userService.logout();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  onClickFirstLevel(item: MenuItem) {
    this.menuService.onClickFirstLevel(item, this.menuService);
  }

  onClickNotification() {
    console.log("old notif:",this.notifications)
    if(Math.floor(Math.random()*5)===0)
      return;
    if(Math.floor(Math.random()*3)===0)
      this.notifications = null;
    else
      this.notifications = [...Array(Math.floor(Math.random()*5)).keys()].map(x=>"Notifica di test ("+x+"-"+(Math.floor(Math.random()*1000))+")");
    console.log("new notif:",this.notifications)
  }

}
