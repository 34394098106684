import { WithActions } from '../table/with-actions';

export class Ente extends WithActions {
  mygovEnteId: number;
  codIpaEnte: string;
  codiceFiscaleEnte: string;
  deNomeEnte: string;
  deLogoEnte: string;
  deInformazioniEnte: string;
  thumbLogoEnte: string;
  hashThumbLogoEnte: string;

  static logo(ente:Ente, thumbnail:boolean = true){
    return thumbnail ? ente.thumbLogoEnte : ente.deLogoEnte;
  }
}
