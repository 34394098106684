

import { ItemCarrello } from './item-carrello';

export class Spontaneo extends ItemCarrello {

  //used to verify email for anonymous
  mailValidationToken: string;

  constructor(){
    super();
    //super.id = getRandomInt(1000000000, 10000000000);
  }

  static setDetails(element: Spontaneo):Spontaneo {
    if(element)
      element.details = [
        {key:'Oggetto del pagamento', value: element.causaleVisualizzata},
        {key:'Numero avviso', value: null},
        {key:'Intestatario avviso', value:
          [element.intestatario?.anagrafica, element.intestatario?.codiceIdentificativoUnivoco].filter(Boolean).join(' - ')},
      ];
    return element;
  }

}
