import { Injectable } from '@angular/core';

import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {

  private baseApiUrl: string;
  private cittadinoUrl: string;
  private pubCittadinoUrl: string;
  private operatoreUrl: string;
  private pubOperatoreUrl: string;

  constructor(
    conf: ConfigurationService,
  ) {
    this.baseApiUrl = conf.getProperty('baseApiUrl');
    this.cittadinoUrl = this.baseApiUrl;
    this.pubCittadinoUrl = this.cittadinoUrl + 'public/';
    this.operatoreUrl = this.baseApiUrl +'operatore/';
    this.pubOperatoreUrl = this.baseApiUrl + 'public/';
  }

  public getBaseUrlApi(): string {
    return this.baseApiUrl;
  }

  public getPubCittadinoUrl(): string {
    return this.pubCittadinoUrl;
  }

  public getCittadinoUrl(anonymous: boolean = false): string {
    return anonymous ? this.pubCittadinoUrl : this.cittadinoUrl;
  }

  public getOperatoreUrl(): string {
    return this.operatoreUrl;
  }

  public getPubOperatoreUrl(): string {
    return this.pubOperatoreUrl;
  }
}
