import { AccessGuard } from 'projects/mypay4-fe-common/src/public-api';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    LoggedComponent
} from '../../../../mypay4-fe-common/src/lib/components/logged/logged.component';
import { AvvisiComponent } from '../components/avvisi/avvisi.component';
import { CardsComponent } from '../components/cards/cards.component';
import { CarrelloComponent } from '../components/carrello/carrello.component';
import { DatiUtenteComponent } from '../components/dati-utente/dati-utente.component';
import { DebitiComponent } from '../components/debiti/debiti.component';
import { EsitoCarrelloComponent } from '../components/esito-carrello/esito-carrello.component';
import { HomeEnteComponent } from '../components/home-ente/home-ente.component';
import { HomeComponent } from '../components/home/home.component';
import { AboutComponent } from '../components/info/about/about.component';
import { AccessibilityComponent } from '../components/info/accessibility/accessibility.component';
import { CookieComponent } from '../components/info/cookie/cookie.component';
import { FaqComponent } from '../components/info/faq/faq.component';
import { LegalComponent } from '../components/info/legal/legal.component';
import { PrivacyComponent } from '../components/info/privacy/privacy.component';
import { SupportComponent } from '../components/info/support/support.component';
import { LandingComponent } from '../components/landing/landing.component';
import { PagatiComponent } from '../components/pagati/pagati.component';
import { SpontaneoComponent } from '../components/spontaneo/spontaneo.component';
import { CharityEsitoComponent } from '../components/your/charity-esito/charity-esito.component';
import { CharityComponent } from '../components/your/charity/charity.component';
import { ForcedMailValidationGuard } from './forced-mail-validation-guard';

const routes = [
  {path: 'home', component: HomeComponent, data:{menuItemId: 20, breadcrumb:{home:true}}},
  {path: 'info/about', component: AboutComponent, data:{breadcrumb:{}}},
  {path: 'info/faq', component: FaqComponent, data:{breadcrumb:{}}},
  {path: 'info/support', component: SupportComponent, data:{breadcrumb:{}}},
  {path: 'info/accessibility', component: AccessibilityComponent, data:{breadcrumb:{}}},
  {path: 'info/privacy', component: PrivacyComponent, data:{breadcrumb:{}}},
  {path: 'info/legal', component: LegalComponent, data:{breadcrumb:{}}},
  {path: 'info/cookie', component: CookieComponent, data:{breadcrumb:{}}},
  {path: 'logged', component: LoggedComponent, data:{redirectTo: 'cards'}},
  {path: 'landing/:type', component: LandingComponent},
  {path: 'cards', component: CardsComponent, data:{menuItemId: 21, requiresLogin: true, breadcrumb:{home: true}}, canActivate: [ AccessGuard, ForcedMailValidationGuard ]},
  {path: 'utente', component: DatiUtenteComponent, data:{menuItemId: 28, requiresLogin: true, breadcrumb:{}}, canActivate: [ AccessGuard ]},
  {path: 'debiti', component: DebitiComponent, data:{menuItemId: 22, requiresLogin: true, breadcrumb:{}}, canActivate: [ AccessGuard, ForcedMailValidationGuard ]},
  {path: 'pagati', component: PagatiComponent, data:{menuItemId: 23, requiresLogin: true, breadcrumb:{}}, canActivate: [ AccessGuard, ForcedMailValidationGuard ]},
  {path: 'avvisi', component: AvvisiComponent, data:{menuItemId: 24, breadcrumb:{}}, canActivate: [ ForcedMailValidationGuard ]},
  {path: 'spontaneo', component: SpontaneoComponent, data:{menuItemId: 32, breadcrumb:{}}, canActivate: [ ForcedMailValidationGuard ]},
  {path: 'ente/:codIpaEnte', component: HomeEnteComponent, data:{breadcrumb:{}}, canActivate: [ ForcedMailValidationGuard ]},
  {path: 'carrello', component: CarrelloComponent, data:{menuItemId: 26, breadcrumb:{}}, canActivate: [ ForcedMailValidationGuard ]},
  {path: 'esito-carrello', component: EsitoCarrelloComponent, data:{menuItemId: 27, breadcrumb:{label:"Esito pagamento"}}},
  {path: '', redirectTo: 'cards', pathMatch: 'full'},
  {path: 'charity', component: CharityComponent },
  {path: 'charity/esito', component: CharityEsitoComponent },
  {path: '**', redirectTo: 'cards', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
