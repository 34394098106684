<ng-container *ngIf="ente">
  <div class="w100">
    <h2 class="mat-h2 text-center">
      <img *ngIf="ente?.thumbLogoEnte" class="thumbnail-ente" src="{{'data:image/png;base64,'+ente.thumbLogoEnte}}" alt="Immagine Ente" aria-hidden="true">
      {{ ente.deNomeEnte }}
    </h2>
  </div>

  <div *ngIf="deInformazioniEnte" class="container">
    <mat-card class="mat-elevation-z0 ente-info-panel">
      <mat-card-header>
        <div mat-card-avatar aria-hidden="true" role="presentation" alt=""><fa-icon [icon]="iconInfo" size="2x" aria-hidden="true"></fa-icon></div>
        <mat-card-title role="heading" aria-level="1">Informazioni ente</mat-card-title>
      </mat-card-header>
      <mat-card-content [innerHtml]="deInformazioniEnte">
      </mat-card-content>
    </mat-card>
  </div>

  <app-avvisi></app-avvisi>

  <app-spontaneo [cod-ipa-ente]="ente.codIpaEnte"></app-spontaneo>
</ng-container>
