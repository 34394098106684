import { ToastrService } from 'ngx-toastr';
import {
  encodeRFC5987ValueChars, OverlaySpinnerService, PATTERNS, validateFormFun
} from 'projects/mypay4-fe-common/src/public-api';
import { Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faGift } from '@fortawesome/free-solid-svg-icons';

import { Carrello } from '../../../model/carrello';
import { Esito } from '../../../model/esito';
import { Person } from '../../../model/person';
import { Spontaneo } from '../../../model/spontaneo';
import { CarrelloService } from '../../../services/carrello.service';
import { CoreAppElementsActivation, CoreAppService } from '../../../services/core-app.service';
import { RecaptchaService } from '../../../services/recaptcha.service';

@Component({
  selector: 'app-charity',
  templateUrl: './charity.component.html',
  styleUrls: ['./charity.component.scss']
})
export class CharityComponent implements OnInit, OnDestroy {

  iconGift = faGift;
  form: FormGroup;
  formErrors = {};
  formValidationMessages = { 'min':'Inserire un importo maggiore di 0€', 'max': 'Inserire un importo minore di 1000€'};
  private formChangesSub: Subscription;
  private callbackUrl: string;

  amountOptions = [5, 10, 20, 50, 100, 500];

  constructor(
    private route:ActivatedRoute,
    private coreAppService: CoreAppService,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private recaptchaService: RecaptchaService,
    private carrelloService: CarrelloService,
    private location: Location,
    private toastrService: ToastrService,
    private elementRef: ElementRef,
    private overlaySpinnerService: OverlaySpinnerService,
  ) {
    this.form = this.formBuilder.group({
      nome: ['', [Validators.required]],
      cognome: ['', [Validators.required]],
      codFiscale: ['', [Validators.pattern(PATTERNS.codiceFiscale)]],
      email: ['', [Validators.required, Validators.email]],
      anonym: [false, [Validators.required]],
      amount: ['', [Validators.required, Validators.pattern(PATTERNS.importo), Validators.min(0.01), Validators.max(1000)]],
      privacy: [false, [Validators.requiredTrue]],
    });

    this.formChangesSub = this.form.valueChanges.subscribe(validateFormFun(this.form, this.formErrors, this.formValidationMessages));
  }

  ngOnInit(): void {
    const coreAppElements = new CoreAppElementsActivation();
    coreAppElements.sidenav = false;
    coreAppElements.header = false;
    this.coreAppService.setState(coreAppElements);

    this.callbackUrl = this.route.snapshot.queryParamMap.get('callbackUrl');

    this.recaptchaService.init(this.renderer);
  }

  onSubmit(): void {
    const basket = new Carrello();
    basket.versante = new Person();
    basket.versante.anagrafica = this.form.get('nome').value+' '+this.form.get('cognome').value;
    if(this.form.get('codFiscale').value)
      basket.versante.codiceIdentificativoUnivoco = this.form.get('codFiscale').value;
    else
      basket.versante.codiceIdentificativoUnivoco = 'ANONIMO';
    basket.versante.tipoIdentificativoUnivoco = 'F';
    basket.versante.email = this.form.get('email').value;
    basket.totalAmount = +this.form.get('amount').value;
    basket.items = [ new Spontaneo() ];
    basket.items[0].codIpaEnte = 'R_VENETO';
    basket.items[0].codTipoDovuto = 'CHARITY_COVID19';
    basket.items[0].importo = basket.totalAmount;
    basket.items[0].causale = 'Sostegno emergenza coronavirus';
    if(this.form.get('anonym').value)
      basket.items[0].causale += ' - anonimo';
    basket.items[0].intestatario = basket.versante;
    basket.backUrlInviaEsito = window.location.origin + this.location.prepareExternalUrl('charity/esito');
    if(this.callbackUrl)
    basket.backUrlInviaEsito += '?backUrl='+encodeRFC5987ValueChars(this.callbackUrl);

    console.log('submitting payment', JSON.stringify(basket));

    const spinner = this.overlaySpinnerService.showProgress(this.elementRef);
    this.recaptchaService.submitWithRecaptchaHandling<Esito>('checkoutCarrello', 
      recaptchaToken => this.carrelloService.processOrderAnonymous(basket, true, recaptchaToken)
    ).subscribe(data => {
      if(data.esito === 'OK'){
        console.log('redirecting to pagopa:',data.url);
        this.overlaySpinnerService.detach(spinner);
        setTimeout(() => {
          window.location.href = data.url;
        }, 3000);
      } else {
        this.overlaySpinnerService.detach(spinner);
        this.toastrService.error(data.esito+': '+data.returnMsg,'Errore pagamento', {disableTimeOut: true});
      }
    })
  }

  ngOnDestroy(): void {
    this.formChangesSub?.unsubscribe();
    this.recaptchaService.deactivate();
  }

}
